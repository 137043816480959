import React from "react";
import { PropTypes } from "prop-types";
import { Row, Col, Typography } from "antd";
import Image from "../ImageQuerys/LocalImages";

const { Title } = Typography;

const LocalRestaurants = (props) => {
  const { data, nav, title } = props;
  return (
    <div className="rest" id={nav}>
      <Row className="pos-section__text" style={{ marginBottom: 40 }}>
        <Col span={24}>
          <Title level={2}>
            {" "}
            {title || "Who we serve"}
            <span className="blue_dot">.</span>{" "}
          </Title>
        </Col>
      </Row>
      <div className="restaurant-features">
        <Row gutter={[32, 32]}>
          {data.map((item, idx) => (
            <Col key={idx.toString()} sm={24} md={12} lg={8} xl={8} xs={24}>
              <div className="item-image">
                <Image imageName={item.img} />
              </div>
              <p className="item-content"> {item.content}</p>
              <p className="content text-center">{item.desc}</p>
            </Col>
          ))}
        </Row>
      </div>

      <style jsx global>{`
        .rest {
          position: relative;
          overflow: hidden;
          margin-top: 72px;
        }
        .rest .cra-blog {
          position: relative;
          width: 100%;
          height: auto;
        }
        .rest h2 {
          font-size: 38px;
          font-weight: 700;
          line-height: 1.45;
          color: #090d12;
          margin-bottom: 60px;
          text-align: center;
        }
        .rest .content {
          color: #596779;
          font-size: 16px;
          line-height: 1.9;
        }
        @media (min-width: 768px) and (max-width: 1260px) {
          .rest h2 {
            font-size: 36px !important;
          }
        }
        .rest p {
          font-family: sofia-pro, sans-serif;
          margin: 0 15%;
        }
        .rest .restaurant-features {
          padding: 0 100px;
        }
        .rest .item-image {
          min-width: 200px !important;
          min-height: 200px !important;
          position: relative;
          border-radius: 4px;
          display: table;
          margin: 0 auto;
          box-shadow: 0px 0px 80px -5px #b8b8b8;
          border-radius: 50%;
        }
        @media screen and (max-width: 768px) {
          .rest .item-image {
            min-width: 150px;
            min-height: 150px;
          }
        }
        .rest .item-content {
          margin-top: 16px;
          font-family: sofia-pro, sans-serif;
          font-size: 18px;
          font-style: normal;
          font-weight: 800;
          line-height: 19px;
          text-align: center;
          background: white;
          box-shadow: rgb(24 36 65 / 20%) 0px 91px 183px,
            rgb(24 36 65 / 14%) 0px 38.0176px 76.453px,
            rgb(24 36 65 / 12%) 0px 20.326px 40.8754px,
            rgb(24 36 65 / 10%) 0px 11.3946px 22.9144px,
            rgb(24 36 65 / 8%) 0px 6.05159px 12.1697px,
            rgb(24 36 65 / 6%) 0px 2.5182px 5.06408px;
          border-radius: 6.68235px;
          padding: 20px;
          position: relative;
          bottom: 10%;
        }
        @media screen and (max-width: 768px) {
          .rest {
            padding: 32px 14px 0 14px;
            margin-top: 40px !important;
            margin-bottom: 20px !important;
          }
          .rest .restaurant-features {
            padding: 0;
          }
          .rest .hardware {
            top: 25% !important;
            width: 100% !important;
          }
          .rest h2 {
            font-size: 24px;
          }
          .rest p {
            margin: 0;
          }
        }
      `}</style>
    </div>
  );
};

LocalRestaurants.propTypes = {
  data: PropTypes.string,
  nav: PropTypes.string,
  title: PropTypes.string,
};
LocalRestaurants.defaultProps = {
  data: "",
  nav: "",
  title: "",
};

export default LocalRestaurants;
